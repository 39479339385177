import { EnvironmentModel } from './environment.model';
import { PROJECT_NAME, VERSION } from './environment.variables';

export const environment: EnvironmentModel = {
  projectName: PROJECT_NAME,
  version: VERSION,
  production: false,
  env: 'dev',
  api: 'https://api.greenpharm.touch4dev.net/api',
  auth: {
    authority: 'https://api.greenpharm.touch4dev.net',
    clientId: 'greenpharm.erp',
    scope: 'openid profile greenpharm.erp.profile greenpharm.erp.api',
    responseType: 'code',
    redirectUrl: 'https://greenpharm.touch4dev.net/callback',
    postLogoutRedirectUri: 'https://greenpharm.touch4dev.net/login',
    postLoginRoute: '/app',
    forbiddenRoute: '/forbidden',
    unauthorizedRoute: '/login',
    silentRenew: true,
    silentRenewUrl: 'https://greenpharm.touch4dev.net/silent-renew.html',
    maxIdTokenIatOffsetAllowedInSeconds: 40,
    autoUserInfo: true,
    historyCleanupOff: false,
    startCheckSession: false,
    logLevel: 0,
    secureRoutes: ['https://api.greenpharm.touch4dev.net/api'],
  },
};
